import React, {useState} from "react";
import './style.css'

const S3_BUCKET = 'images-compute';
const REGION = 'eu-west-2';
const MAX_SIZE = 512;

let imageHeight = 0;
let imageWidth = 0;

/**
 * Dot product between two lists.
 *
 * @param a first list of numbers
 * @param b second list of numbers
 * @returns value of dot product
 */
function dot(a, b) {
    return a.map((x, i) => a[i] * b[i]).reduce((m, n) => m + n);
}


/**
 * Computes the amount of flies at the end of the computation.
 *
 * @param initialFlies initial amount of flies selected by the user
 * @param iterations number of iterations selected by the user
 * @returns final amount of flies
 */
function calculateFlies(initialFlies, iterations) {
    for (let i = 0; i < iterations * 0.6; i++) {
        initialFlies += Math.floor(Math.sqrt(initialFlies))
    }
    return initialFlies;
}


/**
 * Predicts the screen percentage taken by the flies according the parameters chosen by the user.
 *
 * @param initialFlies initial amount of flies selected by the user
 * @param radius radius of the flies selected by the user
 * @param iterations number of iterations selected by the user
 * @param imageWidth width of the image selected by the user
 * @param imageHeight height of the image selected by the user
 * @returns {string} a message according to the percentage of flies on the screen
 */
function getPrediction(initialFlies, radius, iterations, imageWidth, imageHeight) {
    if (imageWidth === 0 || imageHeight === 0) {
        return "Select an image"
    }
    let flies = calculateFlies(initialFlies, iterations)
    let imageSize = imageWidth * imageHeight

    let x0 = flies / imageSize
    let x1 = (Math.PI * radius * radius) / imageSize

    let param = [0.000, 43.555, 427.592, -1142.906, 3732.981, -94872.424, 10421.013, -38850.062, -252584.122, 6717179.506]
    let intercept = -0.34

    let input = [1, x0, x1, x0 * x0, x0 * x1, x1 * x1, x0 * x0 * x0, x0 * x0 * x1, x0 * x1 * x1, x1 * x1 * x1]

    let enough = dot(param, input) + intercept > 0.75

    if (enough) {
        return "Good to go!"
    }
    return "There may be empty spaces at the end. Try increasing some settings"
}


/**
 * Update the prediction message according to the parameters chosen by the user.
 *
 * @param setPrediction setter of the prediction message
 */
function updatePrediction(setPrediction) {
    let initial_population = parseInt(document.getElementById('initialFliesSlider').value)
    let iterations = parseInt(document.getElementById('iterationsSlider').value)
    let radius = parseInt(document.getElementById('radiusSlider').value)

    let prediction = getPrediction(initial_population, radius, iterations, imageWidth, imageHeight)

    setPrediction(prediction)
}


/**
 * Update the image height and width according to the image selected by the user.
 *
 * @param setPrediction setter of the prediction message
 */
function updateImageSize(setPrediction) {
    const files = document.getElementById("photo-upload").files;
    var image = new Image()
    image.src = URL.createObjectURL(files[0])
    image.onload = function () {
        imageHeight = this.height;
        imageWidth = this.width;

        let max = Math.max(imageHeight, imageWidth)
        if (max > MAX_SIZE) {
            imageHeight = imageHeight * MAX_SIZE / max
            imageWidth = imageWidth * MAX_SIZE / max
        }

        console.log(imageHeight, imageWidth)

        updatePrediction(setPrediction);
    }
}


/**
 * Generates a slider with the given parameters.
 *
 * @param props list of parameters in the HTML tag
 * @returns {JSX.Element} slider
 */
function Slider(props) {
    return (
        <div id={"slider"}>
            <label htmlFor="customRange2" className="form-label">{props.name}</label>
            <input type="range" className="form-range" min={props.min} max={props.max} id={props.id}
                   defaultValue={props.var} onChange={(a) => {
                var newValue = document.getElementById(props.id).value
                props.function(newValue)

                updatePrediction(props.prediction)
            }}/>

            {props.var}
        </div>
    );
}

function App() {
    const {CognitoIdentityClient} = require("@aws-sdk/client-cognito-identity");
    const {
        fromCognitoIdentityPool,
    } = require("@aws-sdk/credential-provider-cognito-identity");
    const {
        S3Client,
        PutObjectCommand,
        ListObjectsCommand,
    } = require("@aws-sdk/client-s3");


    const s3 = new S3Client({
        region: REGION,
        credentials: fromCognitoIdentityPool({
            client: new CognitoIdentityClient({region: REGION}),
            identityPoolId: "eu-west-2:50dd087a-03ad-42b4-bb99-45cfb96c1d92", // IDENTITY_POOL_ID
        }),
    });

    const albumBucketName = S3_BUCKET; //BUCKET_NAME
    let newImage = '';
    let file = null;

    const [imageFlies, setImageFlies] = useState('')

    /**
     * Upload image selected by user to S3 bucket
     */
    const addPhoto = async () => {

        const files = document.getElementById("photo-upload").files;
        try {
            await s3.send(
                new ListObjectsCommand({
                    Bucket: albumBucketName
                })
            );
            file = files[0];

            const fileName = new Date().valueOf() + file.name;
            const fileNameSplit = fileName.split('.')
            const photoKey = fileNameSplit[0] + '_' + initialFlies + '_' + iterations + '_' + radius + '.' + fileNameSplit[1]
            newImage = fileNameSplit[0] + '_' + initialFlies + '_' + iterations + '_' + radius + '_compute.' + fileNameSplit[1]

            const uploadParams = {
                Bucket: albumBucketName,
                Key: photoKey,
                Body: file
            };

            try {
                await s3.send(new PutObjectCommand(uploadParams));
                alert("Successfully uploaded photo. Wait a few seconds for the new image to be computed.");
            } catch (err) {
                return alert("There was an error uploading your photo: ", err.message);
            }

        } catch (err) {
            if (!files.length) {
                return alert("Choose a file to upload first.");
            }
        }

        await getPhoto(newImage)
    };

    /**
     * Get the computed image from S3 bucket
     *
     * @param filename name of the image to get
     */
    const getPhoto = async (filename) => {
        let request;
        request = new XMLHttpRequest();


        const address = 'https://images-flies.s3.eu-west-2.amazonaws.com/'.concat(filename);

        do {
            request.open('GET', 'https://images-flies.s3.eu-west-2.amazonaws.com/'.concat(filename), false);
            request.send();
            await new Promise(time => setTimeout(time, 1000));
        } while (request.status === 404);

        const tmp = document.getElementById("output");
        tmp.src = URL.createObjectURL(file)

        setImageFlies(address)

        const box = document.getElementById("imageBox");
        box.style.visibility = "visible"
    }

    const [initialFlies, setInitialFlies] = useState('500')
    const [iterations, setIterations] = useState('100')
    const [radius, setRadius] = useState('1')

    const [prediction, setPrediction] = useState('Select an image')


    return (
        <>
            <div className="background">
                <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css" rel="stylesheet"
                      integrity="sha384-1BmE4kWBq78iYhFldvKuhfTAU6auU8tT94WrHftjDbrCEXSU1oBoqyl2QvZ6jIW3"
                      crossOrigin="anonymous"/>
                <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/js/bootstrap.bundle.min.js"
                        integrity="sha384-ka7Sk0Gln4gmtz2MlQnikT1wXgYsOg+OMhuP+IlRH9sENBO0LRn5q+8nbTov4+1p"
                        crossOrigin="anonymous"/>


                <center>
                    <div className="title">
                        <h1>Make it fly</h1>
                    </div>


                    <div className="file-selector">
                        <div className="mb-3">
                            <label htmlFor="formFile" className="form-label">Choose an image</label>
                            <input className="form-control" type="file" id="photo-upload" accept="image/*"
                                   onChange={() => updateImageSize(setPrediction)}/>
                        </div>
                    </div>

                    <Slider prediction={setPrediction} var={initialFlies} function={setInitialFlies}
                            id="initialFliesSlider" min="500" max="5000" name="Initial flies population"/>
                    <Slider prediction={setPrediction} var={iterations} function={setIterations} id="iterationsSlider"
                            min="100" max="500" name="Iterations"/>
                    <Slider prediction={setPrediction} var={radius} function={setRadius} id="radiusSlider" min="1"
                            max="25" name="Fly size"/>

                    <div className="disclaimer">
                        By pressing <b>Fly</b> you are publicly uploading the image, do <b> not </b> put any sensitive
                        information! <br/>
                        The final image will also be publicly owned.
                    </div>

                    {prediction}

                    <div className="fly-div">
                        <button type="button" className="btn btn-primary" id="fly-button" onClick={() => {
                            addPhoto()
                        }}>
                            Fly
                        </button>
                    </div>

                    <div className="explanation">
                        Once you press fly, an Evolutionary Algorithm will be run on the image. The image is composed by
                        dots that are called flies.
                        Each of them collaborates with the rest. And following biological concepts, they will reproduce
                        and mutate
                        to recreate the original image.
                    </div>

                    <div className="images-div" id="imageBox">
                        <img id="output" className="image-top"/>
                        <img src={imageFlies} className="image-bottom"/>
                    </div>


                    <div className="copyright-div">
                        © 2022 <b>Copyright</b>. King's College London, <a href="http://oussamarakye.com"
                                                                           target="_blank">Oussama Rakye</a>.
                    </div>

                </center>
            </div>
        </>
    );
}


export default App;
